'use es6';

import YesNoData from './components/YesNoData';
const SECURITY_KEY = 'securityAudit';
export default {
  results: [new YesNoData({
    localizedTextKey: 'results.security.https',
    resultFlipCard: true,
    resultKeyPath: [SECURITY_KEY, 'usesHttps']
  }), new YesNoData({
    localizedTextKey: 'results.security.libraries',
    resultFlipCard: true,
    resultKeyPath: [SECURITY_KEY, 'hasNoVulnerableLibraries']
  })]
};