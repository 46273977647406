'use es6';

import I18n from 'I18n';
import RadialData from './RadialData';
const oneSecond = 1000;
const lowThreshold = 5300;
const mediumThreshold = 7301;
const twentySeconds = 20000;
const twoHundred = 200;
class PageLoadingTimeRadialData extends RadialData {
  init(grade) {
    super.init(grade);
    const seconds = I18n.text('results.data.seconds');
    this.displayValueUnit = seconds;
    this.displayRecommendationValue = '< 5.3';
    this.displayRecommendationValueUnit = seconds;
  }
  setGradeType(grade) {
    this.gradeType = this.getGoodOkBadGradeType(grade, lowThreshold, mediumThreshold);
  }
  setScore(grade) {
    // E.g. 20 seconds = 100%, 1 second = 5%
    this.score = Math.round(Math.min(grade, twentySeconds) / twoHundred);
  }

  /**
   * Sets the displayValue and displayValueUnit.
   *
   * E.g.
   *
   *   grade = 5000
   *     displayValue = 5
   *     displayValueUnit = Sec
   *
   */
  setDisplayValue(grade) {
    this.displayValue = Math.max(parseFloat((grade / oneSecond).toFixed(1)), 0.1);
  }
}
export default PageLoadingTimeRadialData;