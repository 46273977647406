'use es6';

import API from './API';
const desktopUrl = 'websitegrader/v2/preview/desktop/';
const mobileUrl = 'websitegrader/v2/preview/mobile/';
const timeout = 30000;
const DESKTOP_SCREENSHOT_WIDTH = 1500;
const DESKTOP_SCREENSHOT_HEIGHT = 800;
const MOBILE_SCREENSHOT_WIDTH = 375;
const MOBILE_SCREENSHOT_HEIGHT = 667;
export default class extends API {
  getDesktopPreview(websiteUrl) {
    return this.makeGetRequest(desktopUrl + websiteUrl, {
      timeout,
      query: {
        width: DESKTOP_SCREENSHOT_WIDTH,
        height: DESKTOP_SCREENSHOT_HEIGHT
      }
    });
  }
  getMobilePreview(websiteUrl) {
    return this.makeGetRequest(mobileUrl + websiteUrl, {
      timeout,
      query: {
        width: MOBILE_SCREENSHOT_WIDTH,
        height: MOBILE_SCREENSHOT_HEIGHT
      }
    });
  }
}