'use es6';

import GradeTypeConstants from '../constants/GradeTypeConstants';
import ScoreConstants from '../constants/ScoreConstants';
export default {
  getOverallGradeType(grade) {
    if (grade >= ScoreConstants.GREAT_THRESHOLD) {
      return GradeTypeConstants.GREAT;
    } else if (grade >= ScoreConstants.GOOD_THRESHOLD) {
      return GradeTypeConstants.GOOD;
    } else if (grade >= ScoreConstants.OK_THRESHOLD) {
      return GradeTypeConstants.OK;
    } else {
      return GradeTypeConstants.BAD;
    }
  }
};