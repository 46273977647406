'use es6';

import invariant from 'react-utils/invariant';
import { createBrowserHistory, createLocation } from 'history';
import { getAppRoot } from './Urls';
const history = createBrowserHistory({
  basename: getAppRoot()
});
export const navigateTo = (...args) => {
  invariant(history, 'history not initialized');
  return history.push(...args);
};
export const replace = (...args) => {
  invariant(history, 'history not initialized');
  return history.replace(...args);
};
export const getFullHref = (to, includeProtocol = true) => {
  invariant(history, 'history not initialized');
  const {
    protocol,
    hostname
  } = window.location;
  const location = typeof to === 'string' ? createLocation(to, null, null, history.location) : to;
  const href = history.createHref(location);
  return `${includeProtocol ? `${protocol}//` : ''}${hostname}${href}`;
};
export default history;