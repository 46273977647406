'use es6';

import { colors } from './colors';
import blobsLeft from 'bender-url!../../../img/blobsLeft.png';
import blobsRight from 'bender-url!../../../img/blobsRight.png';
import interstitialBlobs from 'bender-url!../../../img/InterstitialBlobs.png';
export const defaultTheme = {
  name: 'defaultTheme',
  color: colors.PRIMARYWHITE,
  homePage: {
    backgroundColor: colors.PICKLEDBLUEWOOD,
    background: `url(${blobsLeft}) left top no-repeat, url(${blobsRight}) right bottom no-repeat`
  },
  errorPage: {
    backgroundColor: colors.PICKLEDBLUEWOOD,
    background: `url(${blobsLeft}) left top no-repeat, url(${blobsRight}) right bottom no-repeat`
  },
  loadingPage: {
    backgroundColor: colors.PICKLEDBLUEWOOD,
    background: `url(${interstitialBlobs}) center center no-repeat`,
    backgroundSize: '1003px 708px'
  },
  resultsPage: {
    overallResults: {
      background: `linear-gradient(45deg, ${colors.MOODYBLUE} 0%, ${colors.PACIFICBLUE} 100%)`
    }
  }
};