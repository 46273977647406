'use es6';

import PageLoadingTimeRadialData from './components/PageLoadingTimeRadialData';
import PageSizeRadialData from './components/PageSizeRadialData';
import PageRequestsRadialData from './components/PageRequestsRadialData';
import YesNoData from './components/YesNoData';
const PERFORMANCE_KEY = 'performanceAudit';
export default {
  topResults: [new PageSizeRadialData({
    localizedTextKey: 'results.performance.pageSize',
    resultKeyPath: [PERFORMANCE_KEY, 'pageSize'],
    progressBarIcon: 'icon-download'
  }), new PageRequestsRadialData({
    localizedTextKey: 'results.performance.pageRequests',
    resultKeyPath: [PERFORMANCE_KEY, 'requestCount'],
    progressBarIcon: 'icon-cloud-refresh'
  }), new PageLoadingTimeRadialData({
    localizedTextKey: 'results.performance.pageSpeed',
    resultKeyPath: [PERFORMANCE_KEY, 'loadTime'],
    progressBarIcon: 'icon-timer'
  })],
  middleResults: [new YesNoData({
    localizedTextKey: 'results.performance.browserCaching',
    resultKeyPath: [PERFORMANCE_KEY, 'hasBrowserCaching'],
    resultGradeFlip: true
  }), new YesNoData({
    localizedTextKey: 'results.performance.pageRedirects',
    resultKeyPath: [PERFORMANCE_KEY, 'hasNoRedirects']
  }), new YesNoData({
    localizedTextKey: 'results.performance.imageSize',
    resultKeyPath: [PERFORMANCE_KEY, 'areImagesProperlySized']
  })],
  bottomResults: [new YesNoData({
    localizedTextKey: 'results.performance.minifiedJs',
    resultKeyPath: [PERFORMANCE_KEY, 'isJsMinified']
  }), new YesNoData({
    localizedTextKey: 'results.performance.minifiedCss',
    resultKeyPath: [PERFORMANCE_KEY, 'isCssMinified']
  })]
};