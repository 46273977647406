'use es6';

import $ from 'jquery';
class ComponentData {
  constructor(properties) {
    $.extend(this, properties);
  }

  // Extend to init more data
  init(grade) {
    this.setGrade(grade);
    this.setGradeType(grade);
    this.setDisplayValue(grade);
    return this;
  }
  setGrade(grade) {
    this.resultGrade = grade;
  }

  // Override to set specific type
  setGradeType() {}

  // Override to set specific display values
  setDisplayValue() {}
}
export default ComponentData;