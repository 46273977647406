'use es6';

import { ActionTypes } from '../../constants/AppConstants';
export const applyTheme = theme => {
  return {
    type: ActionTypes.APPLY_THEME,
    payload: {
      theme
    }
  };
};