/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import GraderAPI from '../api/GraderAPI';
import { ActionTypes } from '../constants/AppConstants';
import { ERROR } from '../constants/Paths';
import history, { replace } from '../util/History';
import { matchPath } from 'react-router-dom';
import contactTracker from '../lib/contactTracker';
import { getEmail } from '../selectors/Contact';
import { WEBSITE_GRADER_ERROR } from '../constants/ErrorTypes';
const graderAPI = new GraderAPI();

// check if we are in a widget route, and then add the part of path.
const matchReplace = (...args) => {
  const match = matchPath(history.location.pathname, {
    path: '/widget',
    exact: false,
    strict: false
  });
  if (match) {
    return replace(`/widget${args[0]}`);
  }
  return replace(...args);
};
export default {
  getGrade(websiteUrl) {
    return (dispatch, getState) => {
      const email = getEmail(getState());
      dispatch({
        type: ActionTypes.GRADE_WEBSITE_POLLING,
        payload: {
          websiteUrl
        }
      });
      graderAPI.get(websiteUrl).then(response => {
        if (response.status === 200) {
          const websiteGrade = response.responseJSON;
          dispatch({
            type: ActionTypes.WEBSITE_GRADED,
            payload: websiteGrade
          });
          if (email) {
            contactTracker.saveContact(email, websiteGrade);
          }
        }
      }, response => {
        if (response.status === 404) {
          dispatch(this.gradeWebsite(websiteUrl));
        } else {
          const errorPayload = {
            websiteUrl
          };
          let errorPath = `${ERROR}/${WEBSITE_GRADER_ERROR}`;
          if (response.responseJSON && response.responseJSON.errorType) {
            const {
              errorType
            } = response.responseJSON;
            errorPayload.errorType = errorType;
            errorPath = `${ERROR}/${errorType}`;
          }
          dispatch({
            type: ActionTypes.WEBSITE_GRADED_ERROR,
            payload: errorPayload
          });
          matchReplace(errorPath);
        }
      });
    };
  },
  gradeWebsite(websiteUrl) {
    return dispatch => {
      dispatch({
        type: ActionTypes.GRADE_WEBSITE,
        payload: {
          websiteUrl
        }
      });
      graderAPI.post(websiteUrl).then(response => {
        if (response.status >= 200 && response.status < 300) {
          dispatch({
            type: ActionTypes.GRADE_WEBSITE_POLLING
          });
        }
      }, () => {
        dispatch({
          type: ActionTypes.WEBSITE_GRADED_ERROR,
          payload: {
            websiteUrl
          }
        });
        matchReplace(ERROR);
      });
    };
  },
  storeContact(contactEmail) {
    return dispatch => {
      dispatch({
        type: ActionTypes.STORE_CONTACT,
        payload: {
          contactEmail
        }
      });
    };
  },
  resultsPageUnmounted() {
    return {
      type: ActionTypes.RESULTS_PAGE_UNMOUNTED
    };
  }
};