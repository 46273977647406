/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/prefer-hubspot-url-utils */

'use es6';

import enviro from 'enviro';
const isUI = window.location.origin.indexOf('app.hubspotqa.com') > -1;
const APP_ROOT = enviro.deployed() && !isUI ? '/' : '/websitegrader/';
const LANG_REGEX = /^\/(de|en|pt|ja|es|fr)/gm;
export const getAppRoot = () => {
  const lang = window.I18N_MANUAL_LANG;
  let path = APP_ROOT;
  switch (lang) {
    case 'pt':
    case 'pt-br':
      path = `/pt${path}`;
      break;
    case 'de':
    case 'es':
    case 'fr':
    case 'ja':
      path = `/${lang}${path}`;
      break;
    default:
      break;
  }
  return path;
};
export const parseUtmParams = () => {
  if (!window.location.search) return '';
  const searchParams = window.location.search.slice(1).split('&').filter(param => param.indexOf('utm_') !== -1);
  return searchParams.join('&');
};
export const getSearchParams = () => {
  if (!window.location.search) return '';
  return window.location.search;
};
export const getLocalizedUrl = lang => {
  const {
    origin,
    pathname,
    search
  } = window.location;
  const path = pathname.replace(LANG_REGEX, '');
  const langFragment = lang === 'en' ? '' : `/${lang}`;
  return `${origin}${langFragment}${path}${search}`;
};