'use es6';

import { combineReducers } from 'redux';
import grader from './Grader';
import preview from './Preview';
import contact from './Contact';
import { themeReducer } from '../theming/reducers/themeReducer';
export default combineReducers({
  grader,
  contact,
  preview,
  theming: themeReducer
});