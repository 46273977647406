'use es6';

import noAuthHttp from 'hub-http/clients/noAuthApiClient';
export default class {
  makeGetRequest(url, options) {
    return noAuthHttp.getWithResponse(url, options);
  }
  makePostRequest(url, options) {
    return noAuthHttp.post(url, options);
  }
}