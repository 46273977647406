module.exports = {
  "pageView": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "view": "string",
      "language": "string",
      "theme": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "genericAction": {
    "name": "Action",
    "class": "interaction",
    "properties": {
      "type": "string",
      "website": {
        "type": "string",
        "isOptional": true
      },
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "trialBannerClick": {
    "name": "Action",
    "class": "interaction",
    "properties": {
      "type": "string",
      "websiteUrl": "string",
      "source": "string",
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "academyCourseClick": {
    "name": "Academy Course Click",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-start-free-course-button",
        "clicked-watch-trailer-button",
        "clicked-free-lesson-link"
      ],
      "websiteUrl": "string",
      "source": [
        "course-trailer-modal",
        "results-footer",
        "results-header",
        "performance-section",
        "seo-section",
        "mobile-section",
        "security-section"
      ],
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "freeGuideClick": {
    "name": "Free Guide Click",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-get-guide-button",
        "clicked-get-guide-link"
      ],
      "websiteUrl": "string",
      "source": [
        "results-footer",
        "results-header",
        "performance-section",
        "seo-section",
        "mobile-section",
        "security-section"
      ],
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "socialSharingClick": {
    "name": "Social Sharing Click",
    "class": "interaction",
    "properties": {
      "websiteUrl": "string",
      "socialMediaPlatform": [
        "facebook",
        "twitter",
        "linkedin"
      ],
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  },
  "themeToggleClicked": {
    "name": "Theme changed",
    "class": "interaction",
    "properties": {
      "theme": {
        "type": "string",
        "isOptional": true
      },
      "view": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string"
    },
    "namespace": "websitegrader"
  }
};