'use es6';

import { ActionTypes } from '../constants/AppConstants';
import produce from 'immer';
const defaultState = {
  domain: null,
  loading: true,
  overallGrade: null,
  performanceAudit: null,
  mobileAudit: null,
  seoAudit: null,
  securityAudit: null
};
const Grader = produce((draftState, action) => {
  switch (action.type) {
    case ActionTypes.RESULTS_PAGE_UNMOUNTED:
      draftState.loading = true;
      return draftState;
    case ActionTypes.WEBSITE_GRADED:
      return Object.assign({}, draftState, action.payload, {
        loading: false
      });
    case ActionTypes.WEBSITE_GRADED_ERROR:
      draftState.domain = action.payload.websiteUrl;
      return draftState;
    default:
      return draftState;
  }
}, defaultState);
export default Grader;