'use es6';

import Url from 'urlinator/Url';
export default {
  /**
   * Removes http(s):// and everything after the hostname.
   *
   * E.g.
   *
   *    http://example.com
   *    https://example.com
   *    http://example.com/test
   *    http://example.com/test/page
   *    example.com
   *
   *  become:
   *
   *    example.com
   *
   */
  parseWebsiteUrl(websiteAddress) {
    return new Url(websiteAddress).hostname.trim();
  }
};