'use es6';

import YesNoData from './components/YesNoData';
const MOBILE_KEY = 'mobileAudit';
export default {
  results: [new YesNoData({
    localizedTextKey: 'results.mobile.fontSizes',
    resultKeyPath: [MOBILE_KEY, 'usesLegibleFontSizes']
  }), new YesNoData({
    localizedTextKey: 'results.mobile.touchFriendly',
    resultKeyPath: [MOBILE_KEY, 'areTapTargetsProperlySized']
  }), new YesNoData({
    localizedTextKey: 'results.mobile.responsive',
    resultKeyPath: [MOBILE_KEY, 'isSizedForViewport']
  })]
};