import keyMirror from 'react-utils/keyMirror';

/**
 * Used to categorize website grades.
 */
export default keyMirror({
  BAD: null,
  OK: null,
  GOOD: null,
  GREAT: null
});