'use es6';

import YesNoData from './components/YesNoData';
const SEO_KEY = 'seoAudit';
export default {
  results: [new YesNoData({
    localizedTextKey: 'results.seo.indexable',
    resultKeyPath: [SEO_KEY, 'isIndexable']
  }), new YesNoData({
    localizedTextKey: 'results.seo.metaDescription',
    resultKeyPath: [SEO_KEY, 'hasMetaDescription']
  }), new YesNoData({
    localizedTextKey: 'results.seo.plugins',
    resultKeyPath: [SEO_KEY, 'usesNoPlugins']
  }), new YesNoData({
    localizedTextKey: 'results.seo.descriptiveLinks',
    resultKeyPath: [SEO_KEY, 'hasDescriptiveLinks']
  })]
};