/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/prefer-hubspot-url-utils */

import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
import { Flow } from 'signup-constants/Flow';
export const MON109_CMS_DTT_LINK = 'https://app.hubspot.com/signup/trial-signup?intent=trial&trialId=7&hubs_cta=wsg-trial';

// These all have specific marketing tracking on them, specific to what buttons
// and links use them.
export const getFreeCtaLink = (lang, ctaLocation) => {
  let finalLang = lang;
  if (lang === 'en') {
    finalLang = '';
  }
  return new SignupUrlGenerator(Flow.Microapp, {
    query: {
      microapp: 'website-grader',
      'hubs_signup-url': `website.grader.com/${finalLang.toLowerCase()}`,
      'hubs_signup-cta': ctaLocation.toLowerCase()
    },
    env: 'prod'
  }).getUrl();
};
export const CTA_PERFORMANCE = 'https://academy.hubspot.com/lessons/improving-website-performance?hubs_cta=performance-cta';
export const CTA_SEO = 'https://academy.hubspot.com/lessons/enhancing-seo-for-a-website?hubs_cta=seo-cta';
export const CTA_MOBILE = 'https://academy.hubspot.com/lessons/optimizing-a-wesbite-for-mobile?hubs_cta=mobile-cta';
export const CTA_SECURITY = 'https://academy.hubspot.com/lessons/implementing-website-security-best-practices?hubs_cta=security-cta';
export const LanguageCTALinks = {
  fr: 'https://offers.hubspot.fr/optimisation-site-web?hubs_cta=fr-CTA',
  de: 'https://offers.hubspot.de/website-optimierung?hubs_cta=de-CTA',
  pt: 'https://br.hubspot.com/ofertas/otimizacao-de-pagina?hubs_cta=br-CTA',
  ja: 'https://offers.hubspot.jp/website-optimization-guide?hubs_cta=jp-CTA',
  es: 'https://offers.hubspot.es/optimizacion-web?hubs_cta=es-CTA'
};