'use es6';

import $ from 'jquery';
const selectors = {
  htmlAndBody: 'html, body'
};
export default {
  /**
   * Scroll to a particular node on the page.
   *
   * @param {Node|String} scrollToNode - Node to scroll to
   */
  scrollTo(scrollToNode) {
    $(selectors.htmlAndBody).animate({
      scrollTop: $(scrollToNode).offset().top
    }, 1000);
  }
};