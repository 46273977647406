/* hs-eslint ignored failing-rules */
/* eslint-disable react-hooks/exhaustive-deps */

'use es6';

import { useEffect } from 'react';
const useWidowPostMessage = callback => {
  useEffect(() => {
    const handler = event => {
      event.preventDefault();
      if (typeof event.data !== 'object') return;
      if (!event.data.type) return;
      callback(event);
    };
    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, []); // empty array => run only once

  const postMessage = message => {
    if (!window || !window.parent) {
      return;
    }
    window.parent.postMessage(JSON.stringify(message), '*');
  };
  return [postMessage];
};
export default useWidowPostMessage;