'use es6';

import ComponentData from './ComponentData';
import GradeTypeConstants from '../../constants/GradeTypeConstants';
class RadialData extends ComponentData {
  init(grade) {
    super.init(grade);
    this.setScore(grade);
  }

  // Override
  setScore() {}

  // Override
  setDisplayValue() {}

  // Helper
  getGoodOkBadGradeType(grade, goodThreshold, okThreshold) {
    if (grade < goodThreshold) {
      return GradeTypeConstants.GOOD;
    } else if (grade < okThreshold) {
      return GradeTypeConstants.OK;
    } else {
      return GradeTypeConstants.BAD;
    }
  }
}
export default RadialData;