'use es6';

import I18n from 'I18n';
import ComponentData from './ComponentData';
import GradeTypeConstants from '../../constants/GradeTypeConstants';
class YesNoData extends ComponentData {
  setGradeType(grade) {
    if (this.resultGradeFlip) {
      // Nand the result as a 'true' value grade is not always positive
      grade = !(this.resultGradeFlip && grade);
    }

    // If 'grade' is true, that's good
    this.gradeType = grade ? GradeTypeConstants.GOOD : GradeTypeConstants.BAD;
  }
  setDisplayValue() {
    const yesText = I18n.text('results.data.yep');
    const noText = I18n.text('results.data.nah');
    this.displayValue = this.gradeType === GradeTypeConstants.BAD && this.resultGradeFlip ? yesText : noText;
    this.displayRecommendationValue = this.resultGradeFlip ? noText : yesText;
  }
}
export default YesNoData;