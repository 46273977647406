'use es6';

import invariant from 'react-utils/invariant';
export function logError(err) {
  if (console.error && err instanceof Error) {
    console.error(err);
  } else {
    console.log(err);
  }
}
export default class LocalStorageCache {
  constructor({
    storageKey
  }) {
    invariant(storageKey, 'Storage key must be supplied to LocalStorageCache');
    this._storageKey = `website-grader-ui/${storageKey}`;
  }
  get() {
    let stored = null;
    try {
      stored = window.localStorage.getItem(this._storageKey);
    } catch (e) {
      logError(e);
    }
    try {
      return JSON.parse(stored);
    } catch (e) {
      void e;
    }
    return stored;
  }
  put(value) {
    let toStore = value;
    try {
      toStore = JSON.stringify(toStore);
    } catch (e) {
      void e;
    }
    try {
      return window.localStorage.setItem(this._storageKey, toStore);
    } catch (e) {
      logError(e);
    }
    return null;
  }
  delete() {
    try {
      return window.localStorage.removeItem(this._storageKey);
    } catch (e) {
      logError(e);
    }
    return null;
  }
}