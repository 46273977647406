'use es6';

import RadialData from './RadialData';
class PageRequestsRadialData extends RadialData {
  init(grade) {
    super.init(grade);
    this.displayRecommendationValue = '< 30';
  }
  setGradeType(grade) {
    this.gradeType = this.getGoodOkBadGradeType(grade, 30, 80);
  }
  setScore(grade) {
    this.score = Math.min(grade, 100);
  }
  setDisplayValue(grade) {
    this.displayValue = grade;
  }
}
export default PageRequestsRadialData;