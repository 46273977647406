'use es6';

import { createTracker } from 'usage-tracker-public';
import events from '../../events.yaml';
import { ActionTypes } from '../constants/AppConstants';
import LanguageUtil from '../util/LanguageUtil';
const appName = 'WebsiteGrader';
const listenToEvents = [ActionTypes.GRADE_WEBSITE, ActionTypes.WEBSITE_GRADED, ActionTypes.WEBSITE_GRADED_ERROR, ActionTypes.MARKETING_FREE_MODAL_OPENED, ActionTypes.MARKETING_FREE_MODAL_CLOSED, ActionTypes.MARKETING_FREE_MODAL_LINK_CLICKED];
class UsageTracker {
  constructor() {
    this.tracker = createTracker({
      events,
      properties: {
        namespace: appName
      },
      lastKnownEventProperties: ['view', 'screen']
    });
  }
  log(eventKey = '', eventProperties = {}) {
    this.tracker.track(eventKey, eventProperties);
  }
  logView(view, viewProperties = {}) {
    if (view) {
      this.log('pageView', Object.assign({
        screen: view,
        // Capitalize the first letter of the view
        view,
        language: LanguageUtil.getPrintUserLanguage()
      }, viewProperties));
    }
  }
  logAction(data) {
    this.log('genericAction', data);
  }
  logStateChange({
    routes
  }) {
    if (!routes[1]) {
      return;
    }
    const view = routes[1].name;
    this.logView(view);
  }
  logActionChange(action) {
    const {
      type,
      payload
    } = action;
    const resultAction = {
      type
    };
    // Ignore some events
    if (listenToEvents.indexOf(type) < 0) {
      return;
    }
    if (type === ActionTypes.GRADE_WEBSITE || type === ActionTypes.WEBSITE_GRADED_ERROR) {
      resultAction.website = payload.websiteUrl;
    } else if (type === ActionTypes.WEBSITE_GRADED) {
      resultAction.website = payload.domain;
    }
    this.logAction(resultAction);
  }
}
export default new UsageTracker();