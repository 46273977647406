'use es6';

export const isAfterHalloween = () => {
  const now = new Date();
  const halloweenEndTime = new Date('November 1, 2021 01:00:00');
  return now.getTime() > halloweenEndTime.getTime();
};
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
export const isThemingEnabled = () => {
  return window.I18N_MANUAL_LANG === 'en' && !inIframe() && !isAfterHalloween();
};