'use es6';

import { ActionTypes } from '../constants/AppConstants';
import produce from 'immer';
const defaultState = {
  email: ''
};
const Contact = produce((draftState, action) => {
  switch (action.type) {
    case ActionTypes.STORE_CONTACT:
      {
        if (action.payload && action.payload.contactEmail) {
          draftState.email = action.payload.contactEmail;
        }
        return draftState;
      }
    default:
      return draftState;
  }
}, defaultState);
export default Contact;