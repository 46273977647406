/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-confusing-browser-globals */

'use es6';

import API from './API';
import LanguageUtil from '../util/LanguageUtil';
import { getUtk } from '../util/Cookies';
const URL = '/websitegrader/v2/form/submission';
const SUB_SOURCE = 'Website Grader 2015';

// Only supported subscriber languages are listed here (exluding English)
const supportedSubscriberlanguages = {
  de: 'German',
  es: 'Spanish',
  fr: 'French',
  ja: 'Japanese',
  pt: 'Portuguese'
};
const ENGLISH = 'English';
const hubspotutk = getUtk();
export default class extends API {
  getSubscriberLanguage() {
    const userLanguage = LanguageUtil.getUserLanguage();
    return supportedSubscriberlanguages[userLanguage];
  }
  getSubscriberSource(subscriberLanguage) {
    if (!subscriberLanguage) {
      return SUB_SOURCE;
    }

    // E.g. Website Grader German 2015
    return `Website Grader ${subscriberLanguage} 2015`;
  }

  /**
   * Save the contact in the HubSpot portal/form provided.
   */
  save(contactEmail, websiteGrade) {
    const subscriberLanguage = this.getSubscriberLanguage();
    const subscriberSource = this.getSubscriberSource(subscriberLanguage);
    const getDateAtMidnight = () => {
      return new Date().setUTCHours(0, 0, 0, 0);
    };
    const data = {
      formData: {
        content_language: subscriberLanguage || ENGLISH,
        email: contactEmail,
        lifecyclestage: 'subscriber',
        subscribersource_2: subscriberSource,
        wg_recent_website_url: websiteGrade.domain,
        wg_recent_report_url: location.href,
        wg_overall_score: websiteGrade.overallGrade,
        wg_performance_score: websiteGrade.performanceAudit.grade,
        wg_mobile_responsiveness_score: websiteGrade.mobileAudit.grade,
        wg_seo_score: websiteGrade.seoAudit.grade,
        wg_security_score: websiteGrade.securityAudit.grade,
        wg_submission_date: getDateAtMidnight()
      },
      hutk: hubspotutk,
      pageUri: window.location.href
    };
    return this.makePostRequest(URL, {
      data
    });
  }
}