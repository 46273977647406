'use es6';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import UsageTracker from '../lib/usageTracker';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Usage tracking middleware should be moved to a better handler pattern.
// this was done to support the redux update.
const usageTrackerMiddleware = () => next => action => {
  UsageTracker.logActionChange(action);
  return next(action);
};
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, usageTrackerMiddleware))(createStore);
export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}