'use es6';

import I18n from 'I18n';
import RadialData from './RadialData';
const hundredThousand = 100000;
const oneKb = 1000;
const oneMb = 1000000;
const threeMb = 3000000;
const fiveMb = 5000000;
const tenMb = 10000000;
class PageSizeRadialData extends RadialData {
  init(grade) {
    super.init(grade);
    this.displayRecommendationValue = '< 3';
    this.displayRecommendationValueUnit = I18n.text('results.data.mb');
  }
  setGradeType(grade) {
    this.gradeType = this.getGoodOkBadGradeType(grade, threeMb, fiveMb);
  }
  setScore(grade) {
    this.score = Math.round(Math.min(grade, tenMb) / hundredThousand);
  }

  /**
   * Sets the displayValue and displayValueUnit.
   *
   * E.g.
   *
   *   grade = 2000000
   *     displayValue = 2
   *     displayValueUnit = Mb
   *
   */
  setDisplayValue(grade) {
    let displayValue;
    let displayValueUnit;
    if (grade >= oneMb) {
      displayValue = parseFloat((grade / oneMb).toFixed(1));
      displayValueUnit = I18n.text('results.data.mb');
    } else if (grade >= oneKb) {
      displayValue = Math.round(grade / oneKb);
      displayValueUnit = I18n.text('results.data.kb');
    } else {
      displayValue = grade;
      displayValueUnit = I18n.text('results.data.b');
    }
    this.displayValue = displayValue;
    this.displayValueUnit = displayValueUnit;
  }
}
export default PageSizeRadialData;