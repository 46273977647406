'use es6';

import ContactAPI from '../api/ContactAPI';
const contactAPI = new ContactAPI();
class ContactTracker {
  saveContact(contactEmail, websiteGrade) {
    // Store on the HubSpot portal
    contactAPI.save(contactEmail, websiteGrade);
  }
}
export default new ContactTracker();