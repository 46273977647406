'use es6';

import { ActionTypes } from '../constants/AppConstants';
import produce from 'immer';
const defaultState = {
  desktop: {
    url: null,
    error: false
  },
  mobile: {
    url: null,
    error: false
  }
};
const Preview = produce((draftState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PREVIEW_STARTED:
      return defaultState;
    case ActionTypes.DESKTOP_WEBSITE_PREVIEWED:
      draftState.desktop.url = action.payload.url;
      return draftState;
    case ActionTypes.DESKTOP_WEBSITE_PREVIEWED_ERROR:
      draftState.desktop.error = true;
      return draftState;
    case ActionTypes.MOBILE_WEBSITE_PREVIEWED:
      draftState.mobile.url = action.payload.url;
      return draftState;
    case ActionTypes.MOBILE_WEBSITE_PREVIEWED_ERROR:
      draftState.mobile.error = true;
      return draftState;
    default:
      return draftState;
  }
}, defaultState);
export default Preview;