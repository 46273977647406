'use es6';

// Black, grays and whites
const PRIMARYBLACK = '#000000';
const CODGRAY = '#111111';
const ASPHALT = '#2F2F2F';
const MINESHAFT = '#333333';
const TUNDORA = '#4A4A4A';
const EBONYCLAY = '#253342';
const NEPAL = '#99ACC2';
const COSMIC = '#6E344B';
const DOVEGRAY = '#666666';
const DUSTYGRAY = '#9B9B9B';
const SILVERCHALICE = '#999999';
const SILVER = '#CCCCCC';
const ALTO = '#DBDBDB';
const MERCURY = '#E5E5E5';
const GALLERY = '#F0F0F0';
const PRIMARYWHITE = '#FFFFFF';
const CATSKILLWHITE = '#F5F8FA';

// All other colors
const BURNTSIENNA = '#E66E50';
const PICKLEDBLUEWOODALT2 = '#2F4050';
const PICKLEDBLUEWOODALT = '#33475B';
const PICKLEDBLUEWOOD = '#2D3E50';
const BLEUDEFRANCE = '#297BED';
const CHAMBRAY = '#3B5998';
const PICTONBLUE = '#55ACEE';
const ATLANTIS = '#A4BA32';
const MONZA = '#D00243';
const KOBI = '#E8A8BC';
const ZUMTHOR = '#EEF7FF';
const TANGO = '#F47621';
const PRIMARYORANGE = '#F4762F';
const CRUSTA = '#FA862A';
const FLUSHORANGE = '#FF7700';
const LIGHTNINGYELLOW = '#F8BE1C';
const HITPINK = '#FEB48F';
const BONDIBLUE = '#0091B0';
const CORAL = '#FF7A59 ';
const ATOMICTANGERINE = '#FF8F59 ';
const CARIBBEANGREEN = '#00BDA5 ';
const CARNATION = '#F2545B';
const MOODYBLUE = '#6A78D1';
const PACIFICBLUE = '#00A4BD';
const RAJAH = '#F5C26B';
const WILDBLUEYONDER = '#7C98B6';
const KASHMIRBLUE = '#516F90';
const MYSTIC = '#DFE3EB';
const SANJUAN = '#2F5363';
const OUTERSPACE = '#1B2628';

// Bottom FAQ colors
const MEDIUMGREYBACKGROUND = '#F6F9FC';
const LIGHTGREYBORDER = '#B6C7D6';
const DARKTEXT = '#2E475D';
export const colors = {
  PRIMARYBLACK,
  CODGRAY,
  ASPHALT,
  MINESHAFT,
  TUNDORA,
  EBONYCLAY,
  NEPAL,
  COSMIC,
  DOVEGRAY,
  DUSTYGRAY,
  SILVERCHALICE,
  SILVER,
  ALTO,
  MERCURY,
  GALLERY,
  PRIMARYWHITE,
  CATSKILLWHITE,
  BURNTSIENNA,
  PICKLEDBLUEWOODALT2,
  PICKLEDBLUEWOODALT,
  PICKLEDBLUEWOOD,
  BLEUDEFRANCE,
  CHAMBRAY,
  PICTONBLUE,
  ATLANTIS,
  MONZA,
  KOBI,
  ZUMTHOR,
  TANGO,
  PRIMARYORANGE,
  CRUSTA,
  FLUSHORANGE,
  LIGHTNINGYELLOW,
  HITPINK,
  BONDIBLUE,
  CORAL,
  ATOMICTANGERINE,
  CARIBBEANGREEN,
  CARNATION,
  MOODYBLUE,
  PACIFICBLUE,
  RAJAH,
  WILDBLUEYONDER,
  KASHMIRBLUE,
  MYSTIC,
  SANJUAN,
  OUTERSPACE,
  MEDIUMGREYBACKGROUND,
  LIGHTGREYBORDER,
  DARKTEXT
};