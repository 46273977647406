'use es6';

import Languages from '../constants/Languages';
const DEFAULT_LANGUAGE = 'en';
export default {
  isLanguageSupported(language) {
    return Object.keys(Languages).indexOf(language) >= 0;
  },
  /**
   * E.g. de, en, es (default is en)
   */
  getUserLanguage() {
    let userLanguage = window.I18N_MANUAL_LANG;
    if (userLanguage === 'pt-br') {
      userLanguage = 'pt';
    }
    return this.isLanguageSupported(userLanguage) ? userLanguage : DEFAULT_LANGUAGE;
  },
  /**
   * E.g. Deutsch, English, Español
   */
  getPrintUserLanguage() {
    const userLanguage = this.getUserLanguage();
    return Languages[userLanguage];
  },
  getLangKey() {
    return window.I18N_MANUAL_LANG;
  }
};