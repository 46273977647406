import keyMirror from 'react-utils/keyMirror';
export const ActionTypes = keyMirror({
  GRADE_WEBSITE: null,
  GRADE_WEBSITE_POLLING: null,
  WEBSITE_GRADED: null,
  WEBSITE_GRADED_ERROR: null,
  STORE_CONTACT: null,
  DESKTOP_WEBSITE_PREVIEWED: null,
  DESKTOP_WEBSITE_PREVIEWED_ERROR: null,
  MOBILE_WEBSITE_PREVIEWED: null,
  MOBILE_WEBSITE_PREVIEWED_ERROR: null,
  EMAIL_SENDING: null,
  EMAIL_SENT: null,
  SHARE_MODAL_CLOSED: null,
  FETCH_PREVIEW_STARTED: null,
  RESULTS_PAGE_UNMOUNTED: null,
  APPLY_THEME: null
});
export const POLL_TIME_LIMIT = 120000;