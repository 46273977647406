/**
 * Used to track scoring values.
 */
export default {
  // The number of marks available for each section (should amount to 100)
  PERFORMANCE_OVERALL: 30,
  MOBILE_OVERALL: 30,
  SEO_OVERALL: 30,
  SECURITY_OVERALL: 10,
  // Thresholds for great, good, ok (bad = anything under OK)
  GREAT_THRESHOLD: 90,
  GOOD_THRESHOLD: 75,
  OK_THRESHOLD: 50
};