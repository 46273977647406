'use es6';

import Cookies from 'js-cookie';
export const UNKNOWN_UTK = 'unknown';
const getHstc = () => {
  return Cookies.get('__hstc') || null;
};
export const getUtk = () => {
  let utk = Cookies.get('hubspotutk');
  if (!utk) {
    utk = getHstc();
    if (utk) {
      utk = utk.split('.')[1];
    }
  }
  return utk || null;
};