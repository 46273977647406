'use es6';

import { useLocation } from 'react-router-dom';
const useQuery = () => {
  const location = useLocation();
  const {
    search
  } = location;
  if (!search) return {};
  const params = search.slice(1);
  return params.split('&').reduce((result, param) => {
    const values = param.split('=');
    result[values[0]] = values[1];
    return result;
  }, {});
};
export default useQuery;