'use es6';

import { colors } from './colors';
export const halloween = {
  name: 'halloween',
  color: colors.PRIMARYWHITE,
  homePage: {
    background: `radial-gradient(${colors.SANJUAN} 0%, ${colors.OUTERSPACE} 80%)`
  },
  errorPage: {
    background: `radial-gradient(${colors.SANJUAN} 0%, ${colors.OUTERSPACE} 80%)`
  },
  loadingPage: {
    background: `radial-gradient(${colors.SANJUAN} 0%, ${colors.OUTERSPACE} 80%)`
  },
  resultsPage: {
    overallResults: {
      background: `linear-gradient(0deg, ${colors.PRIMARYBLACK} 0%, ${colors.BURNTSIENNA} 80%)`
    }
  }
};