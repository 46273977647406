'use es6';

import LocalStorageCache from '../../util/LocalStorageCache';
import { ActionTypes } from '../../constants/AppConstants';
import themes from '../themes';
import produce from 'immer';
const cache = new LocalStorageCache({
  storageKey: 'theme'
});
const theme = cache.get();
const defaultState = {
  theme: theme || themes.halloween.name
};
export const themeReducer = produce((draftState, action) => {
  switch (action.type) {
    case ActionTypes.APPLY_THEME:
      cache.put(action.payload.theme);
      if (action.payload.theme === 'halloween') {
        draftState.theme = themes.halloween.name;
      } else {
        draftState.theme = themes.defaultTheme.name;
      }
      return draftState;
    default:
      return draftState;
  }
}, defaultState);