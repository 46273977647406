'use es6';

import API from './API';
const URL = 'websitegrader/v2/grade/';
const timeout = 60000;
export default class extends API {
  get(websiteUrl) {
    return this.makeGetRequest(URL + websiteUrl, {
      timeout
    });
  }
  post(websiteUrl) {
    return this.makePostRequest(URL + websiteUrl, {
      timeout
    });
  }
}